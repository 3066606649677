<template>
  <div class="chatroom" @click="hideModal">
    <div class="header">
      <div class="left">
        <img src="../assets/icon_logo_blue.png" alt="" @click="goIndex"> | <span>智慧法务倡导者</span>
      </div>
      <div class="right" @mouseover="showTime=true" @mouseout="showTime=false">
        <img src="../assets/icon_phine_blue16.png" alt=""><span>400-859-1580</span>
        <div class="work" v-show="showTime">8:30 - 17:30</div>
      </div>
    </div>
    <div class="main">
      <div class="main_in_wait" v-if="type=='wait'">
        <div class="main_info">
          <div>已成功通知{{lawyerNum}}位律师</div>
          <div class="time">
            {{counts}}<span>s</span>
          </div>
          <div v-if="counts<60">下单成功</div>
          <div v-if="counts<60">等待律师接单</div>
          <div v-else>暂无律师接单</div>
        </div>
      </div>
      <div class="main_in_chat" v-show="type=='chat'||type=='finish'||type=='waitd'">
        <div class="left">
          <div class="server">{{finishTime=='服务已到期'?finishTime:'服务到期时间：'+finishTime}}</div>
          <el-scrollbar ref="scroll" class="chat">
            <div class="chat_in" ref="innerScroll">
              <div v-for="(item,index) in chatMsg" :key="index">
                <div class="time" v-if="item.showTime">{{item.time}}</div>
                <div class="normal " v-if="item.ext.ShowType=='PushDirectService'">
                  <div class="title blue">常用服务</div>
                  <div class="center">
                    <div>服务名称：<span>{{item.ext.ProductTitle}}</span></div>
                    <div>实付金额：<span>{{Number(item.ext.money).toFixed(2)}}元</span></div>
                  </div>
                  <div class="btn" @click="toPay(index)">立即支付</div>
                </div>
                <div class="normal " v-else-if="item.ext.ShowType=='CustomerBuySuccess'">
                  <div class="title orange">服务购买成功</div>
                  <div class="center">
                    <div>服务名称：<span>{{item.ext.OrderTitle}}</span></div>
                    <div>实付金额：<span>{{Number(item.ext.OrderNeedPay).toFixed(2)}}元</span></div>
                  </div>
                </div>
                <div class="normal " v-else-if="item.ext.ShowType=='InviteComment'">
                  <div class="center">
                    <div>{{item.data}}</div>
                  </div>
                  <div class="btn" @click="comment(index)">去评价</div>
                </div>
                <div class="normal " v-else-if="item.ext.ShowType=='ShowCase'">
                  <div class="title orange">服务确认</div>
                  <div class="center">
                    <div>律师已为您登记案件，请确认</div>
                  </div>
                  <div class="btn" @click="toPay(index)">查看并确认</div>
                </div>
                <div class="normal " v-else-if="item.ext.ShowType=='Document'">
                  <div class="title green">文书交付通知</div>
                  <div class="center">
                    <div>律师已将文书文件发送至您填写的接收邮箱，请注意查收！</div>
                    <div>服务内容：<span>文书审/改/写</span></div>
                  </div>
                  <div class="btn btn2">
                    <div @click="wsChange(index)">修改文书</div>
                    <div @click="noWsChange(index)">无需修改</div>
                  </div>
                </div>
                <div class="normal " v-else-if="item.ext.ShowType=='DeliverFileForWritingOrder'">
                  <div class="title green">文书交付通知</div>
                  <div class="center">
                    <div>律师已交付文书并同步发送至你的邮箱，请注意查收！</div>
                    <div>服务内容：<span>{{item.ext.OrderTitle}}</span></div>
                  </div>
                  <div class="btn" @click="previewFile(item.ext.FileUrl,item.ext.FileName)">查看交付文件</div>
                </div>
                <div class="user" v-else-if="item.msgType=='text'&&!item.ext.ShowType&&item.from==user.MemberThirdId">
                  <div class="word" v-html="item.data"></div>
                  <img
                    :src="user.MemberPersonalPic!=''&&user.MemberPersonalPic!=null?user.MemberPersonalPic:require('../assets/icon_head82.png')"
                    alt="" class="pic">
                </div>
                <div class="user" v-else-if="item.msgType=='picture'&&!item.ext.ShowType&&item.from==user.MemberThirdId">
                  <div class="word" ><img :src="item.url" alt="" @click="bigImg(item.url)"></div>
                  <img
                    :src="user.MemberPersonalPic!=''&&user.MemberPersonalPic!=null?user.MemberPersonalPic:require('../assets/icon_head82.png')"
                    alt="" class="pic">
                </div>
                <div class="user" v-else-if="item.msgType=='audio'&&!item.ext.ShowType&&item.from==user.MemberThirdId">
                  <div class="word"  @click="playAudio(item.url,item.length,index)">
                    <span>{{item.length}}"</span>
                    <div class="wifi-cont">
                        <div class="wifi-circle first"></div>
                        <div class="wifi-circle second" :class="item.open?'second_animation':''"></div>
                        <div class="wifi-circle third" :class="item.open?'third_animation':''"></div>
                    </div>
                  </div>
                  <img
                    :src="user.MemberPersonalPic!=''&&user.MemberPersonalPic!=null?user.MemberPersonalPic:require('../assets/icon_head82.png')"
                    alt="" class="pic">
                </div>
                <div class="user" v-else-if="item.msgType=='file'&&!item.ext.ShowType&&item.from==user.MemberThirdId">
                  <div class="word file"  @click="previewFile(item.url,item.filename)">
                    <div class="name">{{item.filename}}</div>
                    <img :src="item.icon" alt="">
                  </div>
                  <img
                    :src="user.MemberPersonalPic!=''&&user.MemberPersonalPic!=null?user.MemberPersonalPic:require('../assets/icon_head82.png')"
                    alt="" class="pic">
                </div>
                <div class="lawyer"
                  v-else-if="(item.msgType=='text'&&!item.ext.ShowType&&item.from==lawyerThirdId)||item.ext.ShowType=='GetOrder'">
                  <img :src="lawyerInfo.MemberPersonalPic" alt="" class="pic">
                  <div class="word" v-html="item.data"></div>
                </div>
                <div class="lawyer" v-else-if="item.msgType=='picture'&&item.from==lawyerThirdId">
                  <img :src="lawyerInfo.MemberPersonalPic" alt="" class="pic">
                  <div class="word"><img :src="item.url" alt="" @click="bigImg(item.url)"></div>
                </div>
                <div class="lawyer" v-else-if="item.msgType=='audio'&&item.from==lawyerThirdId">
                  <img :src="lawyerInfo.MemberPersonalPic" alt="" class="pic">
                  <div class="word" @click="playAudio(item.url,item.length,index)">
                    <div class="wifi-cont">
                        <div class="wifi-circle first"></div>
                        <div class="wifi-circle second" :class="item.open?'second_animation':''"></div>
                        <div class="wifi-circle third" :class="item.open?'third_animation':''"></div>
                    </div>
                    <span>{{item.length}}"</span>
                  </div>
                </div>
                <div class="lawyer" v-else-if="item.msgType=='file'&&item.from==lawyerThirdId">
                  <img :src="lawyerInfo.MemberPersonalPic" alt="" class="pic">
                  <div class="word file"  @click="previewFile(item.url,item.filename)">
                     <div class="name">{{item.filename}}</div>
                     <img :src="item.icon" alt="">
                  </div>
                </div>
                <!-- v-if="item.ext.ShowType=='QiangDan'||item.ext.ShowType=='CancelOrderCase'" -->
                <div class="tip" v-else>{{item.data}}</div>
              </div>
            </div>
          </el-scrollbar>
          <div class="foot">
            <img class="icon emo_icon"
              :src="hoverEmo?require('../assets/icon_face_focused.png'):require('../assets/icon_face_default.png')"
              alt="" @mouseover="hoverEmo=true" @mouseleave="hoverEmo=false" @click.stop="emoFun">
              <div class="pic_div">
                <img class="icon pic_icon"
                :src="hoverPic?require('../assets/icon_picture_focused.png'):require('../assets/icon_picture_default.png')"
                alt="" >
                <input  type="file" accept="image/*" id="imgDom" ref="imgDom" @change="pictureChange"  @mouseover="hoverPic=true" @mouseleave="hoverPic=false"/>
              </div>
              <div class="pic_div">
                <img class="icon file_icon"
              :src="hoverFile?require('../assets/icon_file_focused.png'):require('../assets/icon_file_default.png')"
              alt="">
                <input type="file" accept=".xls,.xlsx,.txt,.ppt,.pptx,.pdf,.doc,.docx" id="fileDom" ref="fileDom" @change="fileChange"  @mouseover="hoverFile=true" @mouseleave="hoverFile=false"/>
              </div>
              
            <el-input type="textarea" resize="none" v-model="message" :disabled="closeInput" @input="msgInput">
            </el-input>
            <div class="send " :class="canSend?'active':''" @click="sendFun">发送</div>
            <div class="emojiDiv" v-if="showEmoji">
              <img v-for="(v,i) in emojiObj" :src="require(`../assets/faces/${v}`)" :key="i" alt=""
                @click="selectEmoji(i)">
            </div>
          </div>
        </div>
        <div class="right">
          <div class="top">
            <img :src="lawyerInfo.MemberPersonalPic" alt="">
            <div class="name">
              <div>{{lawyerInfo.MemberRealName}}</div>
              <div>执业{{lawyerInfo.LawyerPracticeYears}}年</div>
              <div>{{lawyerInfo.Level}}律师</div>
            </div>
          </div>
          <div class="good">{{lawyerInfo.ProfessionalField}}</div>
          <div class="thrity">近30天服务情况</div>
          <div class="three">
            <div class="item">
              <img src="../assets/icon_serve.png" alt="">
              <div>服务</div>
              <div>{{lawyerInfo.ServiceCountMonth}}人</div>
            </div>
            <div class="item">
              <img src="../assets/icon_evaluate.png" alt="">
              <div>好评率</div>
              <div>{{lawyerInfo.Satisfaction}}</div>
            </div>
            <div class="item">
              <img src="../assets/icon_money.png" alt="">
              <div>送心意</div>
              <div>{{lawyerInfo.MindCountMonth}}人次</div>
            </div>
          </div>
          <div class="pj" v-if="JSON.stringify(newComment)!='{}'">
            <div>最新评价</div>
            <div>
              <span>{{newComment.MemberMoblie_kh}}</span>
              <div class="star">
                <img src="../assets/icon_shape_selected.png" alt="" v-for="i in newComment.Stars">
                <img src="../assets/icon_shape_normal.png" alt="" v-for="i in newComment.gray_star">
              </div>
            </div>
            <div>{{newComment.CommentDes}}</div>
            <div>{{newComment.CreateDate}}</div>
          </div>
          <div class="pj" v-else>
            <div>最新评价</div>
            <div style="margin:30px auto;text-align:center;">暂无评价</div>
          </div>
          <div class="tip" v-if="type=='waitd'">等待律师接单</div>
          <div class="btn" v-if="type=='chat'">
            <div @click="goPhone">电话咨询</div>
            <div @click="comment('all')">评价律师</div>
          </div>
          <div class="scan" v-if="type=='finish'">
            <div class="er"><img src="../assets/ilvdo.jpg" alt=""></div>
            <div>
              <div>扫码进入小程</div>
              <div>序继续咨询</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 文书 -->
    <el-dialog class="wenshu" title="" top="20vh" :show-close="false" :visible.sync="dialogVisibleWS"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleWS=false">
      <div class="title">请确认文书信息</div>
      <div class="info">
        <div class="item">
          <div>服务内容：</div>
          <div>{{dialogVal.ProductTitle}}</div>
        </div>
        <div class="item">
          <div>文书名称：</div>
          <div>{{dialogVal.Description}}</div>
        </div>
        <div class="item">
          <div>交付文档：</div>
          <div>{{dialogVal.NeedDeliver==1?'是':'否'}}</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>交付时间：</div>
          <div>{{dialogVal.Layefinish}}</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>工时预估：</div>
          <div>{{Number(dialogVal.T1)+Number(dialogVal.T2)+Number(dialogVal.T3)+Number(dialogVal.T4)}}小时</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>需求沟通：</div>
          <div>{{dialogVal.T1}}小时</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>查阅检索：</div>
          <div>{{dialogVal.T2}}小时</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>案情梳理：</div>
          <div>{{dialogVal.T3}}小时</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>文书撰写：</div>
          <div>{{dialogVal.T4}}小时</div>
        </div>
        <div class="item" v-if="dialogVal.NeedDeliver==1">
          <div>接收邮箱：</div>
          <div>
            <el-input v-model="email" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="item" v-if="dialogVal.Remark">
          <div>备注：</div>
          <div>{{dialogVal.Remark}}</div>
        </div>
      </div>
      <div class="btn" @click="toBuy('ws')">立即购买</div>
    </el-dialog>
    <!-- 律师函 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisibleLSH"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleLSH=false">
      <div class="title">请确认律师函信息</div>
      <div class="info">
        <div class="item">
          <div>案由：</div>
          <div>{{dialogVal.Description}}</div>
        </div>
        <div class="item">
          <div>交付时间：</div>
          <div>{{dialogVal.Layefinish}}</div>
        </div>
        <div class="item">
          <div>接收邮箱：</div>
          <div>
            <el-input v-model="email" placeholder="请输入"></el-input>
          </div>
        </div>
        <div class="item" v-if="dialogVal.Remark">
          <div>备注：</div>
          <div>{{dialogVal.Remark}}</div>
        </div>
      </div>
      <div class="btn" @click="toBuy('lsh')">立即购买</div>
    </el-dialog>
    <!-- 案件 -->
    <el-dialog class="wenshu case" title="" top="25vh" :show-close="false" :visible.sync="dialogVisibleCase"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleCase=false">
      <div class="title">请确认案件信息</div>
      <div class="info" v-if="dialogVal.CaseTitle=='合同纠纷'">
        <div class="item">
          <div>案由：</div>
          <div>{{dialogVal.CaseTitle}}</div>
        </div>
        <div class="item">
          <div>有无合同：</div>
          <div>{{dialogVal.HasContract==1?'有':'无'}}</div>
        </div>
        <div class="item">
          <div>合同标的：</div>
          <div>{{dialogVal.CaseBiaodi}}</div>
        </div>
        <div class="item">
          <div>合同诉讼地点：</div>
          <div>{{dialogVal.Placeoflitigation}}</div>
        </div>
        <div class="item">
          <div>证据材料：</div>
          <div>{{dialogVal.EvidenceMaterial}}</div>
        </div>
        <div class="item">
          <div>是否是合同负责人咨询：</div>
          <div>{{dialogVal.HasConsult==1?'是':'否'}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='婚姻家庭'">
        <div class="item">
          <div>是否是婚姻关系本人：</div>
          <div>{{dialogVal.IsYourOwn==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>是否已经离婚：</div>
          <div>{{dialogVal.HasDivorce==1?'是':'否'}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasDivorce==0">
          <div>准备采取何种离婚方式：</div>
          <div>{{dialogVal.DivorceReason}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasDivorce==1">
          <div>您的需求是：</div>
          <div>{{dialogVal.DivorceReason}}</div>
        </div>
        <div class="item" v-if="dialogVal.DivorceReason=='起诉离婚'">
          <div>被告户籍所在地：</div>
          <div>{{dialogVal.Placeofdefendant}}</div>
        </div>
        <div class="item" v-if="dialogVal.DivorceReason=='起诉离婚'">
          <div>共同财产：</div>
          <div>{{dialogVal.Commonproperty}}</div>
        </div>
        <div class="item" v-if="dialogVal.DivorceReason=='起诉离婚'">
          <div>有无共同债务：</div>
          <div>{{dialogVal.HasDebt==1?'有':'无'}}</div>
        </div>
        <div class="item" v-if="dialogVal.DivorceReason=='起诉离婚'">
          <div>有无子女：</div>
          <div>{{dialogVal.HasSon==1?'有':'无'}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='借贷'">
        <div class="item">
          <div>有无欠款借款：</div>
          <div>{{dialogVal.HasDebt==1?'有':'无'}}</div>
        </div>
        <div class="item">
          <div>是否有签订还款日期：</div>
          <div>{{dialogVal.HasSignDate==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>是否有对方身份证号码：</div>
          <div>{{dialogVal.HasIDcode==1?'有':'无'}}</div>
        </div>
        <div class="item">
          <div>出借方式：</div>
          <div>{{dialogVal.Way}}</div>
        </div>
        <div class="item">
          <div>金额：</div>
          <div>{{dialogVal.Money}}</div>
        </div>
        <div class="item">
          <div>诉讼地点：</div>
          <div>{{dialogVal.Placeoflitigation}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='劳动纠纷'">
        <div class="item">
          <div>有无劳动合同：</div>
          <div>{{dialogVal.HasContract==1?'有':'无'}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasContract==0">
          <div>是否有证据证明劳动关系：</div>
          <div>{{dialogVal.HasWorkPaper==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>是否缴纳社保：</div>
          <div>{{dialogVal.HasPaySocialSecurity==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>是否有申请过劳动仲裁：</div>
          <div>{{dialogVal.HasLaborArbitration==1?'是':'否'}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasLaborArbitration==1">
          <div>用人单位所在地：</div>
          <div>{{dialogVal.PlaceWork}}</div>
        </div>
        <div class="item">
          <div>工作年限：</div>
          <div>{{dialogVal.WorkAge}}</div>
        </div>
        <div class="item">
          <div>月平均工资：</div>
          <div>{{dialogVal.AvgWages}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='工伤索偿'">
        <div class="item">
          <div>有无劳动合同：</div>
          <div>{{dialogVal.HasContract==1?'有':'无'}}</div>
        </div>
        <div class="item">
          <div>是否缴纳社保：</div>
          <div>{{dialogVal.HasPaySocialSecurity==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>月平均工资：</div>
          <div>{{dialogVal.AvgWages}}</div>
        </div>
        <div class="item">
          <div>是否已经治疗结束：</div>
          <div>{{dialogVal.HasFixedOver==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>是否做过工伤认定：</div>
          <div>{{dialogVal.HasConfirm==1?'是':'否'}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasConfirm==1">
          <div>劳动等级能力：</div>
          <div>{{dialogVal.WorkLevel}}</div>
        </div>
        <div class="item">
          <div>用人单位所在地：</div>
          <div>{{dialogVal.Workplace}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='交通事故'">
        <div class="item">
          <div>是否有交通事故责任认定书：</div>
          <div>{{dialogVal.HasCofirmPaper==1?'是':'否'}}</div>
        </div>
        <div class="item" v-if="dialogVal.HasCofirmPaper==1">
          <div>责任比例（客户）：</div>
          <div>{{dialogVal.DutyProportion}}</div>
        </div>
        <div class="item">
          <div>是否治疗结束：</div>
          <div>{{dialogVal.HasFixedOver==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>治疗费用：</div>
          <div>{{dialogVal.FixedMoney}}</div>
        </div>
        <div class="item">
          <div>当事人的薪资水平：</div>
          <div>{{dialogVal.Wages}}</div>
        </div>
        <div class="item">
          <div>是否有保险：</div>
          <div>{{dialogVal.HasInsurance}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='刑事'">
        <div class="item">
          <div>是否需要会见：</div>
          <div>{{dialogVal.HasNeedMeet==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>进行阶段：</div>
          <div>{{dialogVal.Stage}}{{dialogVal.Stage=='审判阶段'?'-'+dialogVal.StageSp:''}}</div>
        </div>
        <div class="item">
          <div>涉及问题：</div>
          <div>{{dialogVal.Problems}}</div>
        </div>
        <div class="item">
          <div>是否是当事人或者近亲属：</div>
          <div>{{dialogVal.IsClose==1?'是':'否'}}</div>
        </div>
      </div>
      <div class="info" v-if="dialogVal.CaseTitle=='其他'">
        <div class="item">
          <div>是否涉及财产：</div>
          <div>{{dialogVal.HasRelatedToProperty==1?'是':'否'}}</div>
        </div>
        <div class="item">
          <div>起诉地点：</div>
          <div>{{dialogVal.Placeoflitigation}}</div>
        </div>
        <div class="item">
          <div>可能要求赔偿金额：</div>
          <div>{{dialogVal.Compensation}}</div>
        </div>
        <div class="item">
          <div>是否需要陪同谈判：</div>
          <div>{{dialogVal.AccompanyingNegotiation==1?'是':'否'}}</div>
        </div>
      </div>
      <div class="btn2">
        <div @click="cancelCase">取消登记</div>
        <div @click="confirmCase">立即确认</div>
      </div>
    </el-dialog>
    <!-- 案件取消原因 -->
    <el-dialog class="wenshu case" title="" top="30vh" :show-close="false" :visible.sync="dialogVisibleReason"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleReason=false">
      <div class="title">请选择取消原因</div>
      <el-select v-model="value" placeholder="请选择">
        <el-option v-for="item in options" :key="item" :label="item" :value="item">
        </el-option>
      </el-select>
      <div class="btn2">
        <div @click="dialogVisibleReason=false">取消</div>
        <div @click="confirm">确定</div>
      </div>
    </el-dialog>
    <!-- 温馨提示电话咨询 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisibleCall"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleCall=false">
      <div class="title">温馨提示</div>
      <div class="mintip">您尚未购买电话咨询服务，是否立即购买？</div>
      <div class="btn2">
        <div @click="cancelTip">取消</div>
        <div @click="buyCall">立即购买</div>
      </div>
    </el-dialog>
    <!-- 温馨提示电话咨询 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisibleCallConfirm"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleCallConfirm=false">
      <div class="title">温馨提示</div>
      <div class="mintip">确定发起电话咨询吗？</div>
      <div class="btn2">
        <div @click="cancelTel">取消</div>
        <div @click="call">确定</div>
      </div>
    </el-dialog>
    <!-- 温馨提示接听来电 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisibleTip"
      :close-on-click-modal="false" :close-on-press-escape="false" width="410px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleTip=false">
      <div class="title">温馨提示</div>
      <div class="webtip">请使用您尾号（{{last}}）的手机接听律师来电</div>
      <div class="webtip_orange">为保护您和律师的隐私信息，来电将显示网络号码</div>
      <div class="btn" @click="dialogVisibleTip=false;dialogVisibleCallConfirm=false">我知道了</div>
    </el-dialog>
    <!-- 选择订单 -->
    <el-dialog class="wenshu pj" title="" top="20vh" :show-close="false" :visible.sync="dialogVisibleOrder"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleOrder=false">
      <div class="title">请选择评价订单</div>
      <el-scrollbar class="list">
        <div class="item" :class="pjOrder==index?'active':''" v-for="(item,index) in orderList" :key="index"
          @click="choseOrder(index)">
          <div>{{item.OrderTitle}}</div>
          <div>订单状态：{{item.StateName}}</div>
          <div>下单时间：{{item.PayDate}}</div>
          <img
            :src="pjOrder==index?require('../assets/popup_icon_tick_selected.png'):require('../assets/popup_icon_tick_normal.png')"
            alt="">
        </div>
      </el-scrollbar>
      <div class="btn" @click="nextStep">下一步</div>
    </el-dialog>
    <!-- 评价 -->
    <el-dialog class="wenshu" title="" top="20vh" :show-close="false" :visible.sync="dialogVisibleComment"
      :close-on-click-modal="false" :close-on-press-escape="false" width="390px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close" @click="dialogVisibleComment=false">
      <div class="title">请您对当前服务进行评价</div>
      <div class="pj">
        <div class="subtitle">对TA的印象</div>
        <div class="star">
          <img
            :src="pj.star>=index+1?require('../assets/popup_btn_shape_selected.png'):require('../assets/popup_btn_Shape_normal.png')"
            alt="" v-for="(item,index) in 5" :key="index" @click="starFun(index)">
        </div>
        <div class="subtitle">印象标签</div>
        <div class="tag">
          <div :class="pj.tag.indexOf(tag[index])>=0?'active':''" v-for="(item,index) in tag" :key="index"
            @click="tagFun(index)">{{item}}</div>
        </div>
        <el-input type="textarea" resize="none" maxlength="100" placeholder="请输入评价内容" v-model="pj.msg">
        </el-input>
      </div>
      <div class="btn" @click="addComment">提交评价</div>
    </el-dialog>
    <!-- 评价成功 -->
    <el-dialog class="wenshu" title="" top="30vh" :show-close="false" :visible.sync="dialogVisible"
      :close-on-click-modal="false" :close-on-press-escape="false" width="340px">
      <img src="../assets/popup_icon_close2.png" alt="" class="close">
      <div class="success">
        <img src="../assets/login_tick100.png" alt="">
        <div>评价成功</div>
      </div>
      <div class="btn" @click="closePJ">确定</div>
    </el-dialog>
    <div class="modal" v-show="showImg" @click="hideimg">
      <div class="big"><img :src="url" alt="" ref="bigimg" id="bigimg"></div>
    </div>
    <audio style="height:0;width:0;opacity: 0;" id="audio_id" controls>Your
            browser can't support HTML5 Audio
        </audio>
    <!-- <div class="modal" v-show="showFile" >
      <div class="preview" v-if="filetype=='pdf'">
        <img src="../assets/popup_icon_close.png" alt="" class="close" @click.stop="hideFile">
        <pdf v-for="item in numPages" :key="item" :src="preview_url" :page="item" ref="pdf"></pdf>
      </div>
      <div class="preview" v-else-if="filetype=='txt'" >
        <img src="../assets/popup_icon_close.png" alt="" class="close" @click.stop="hideFile">
        <pre >{{preview_url}}</pre>
      </div>
      <div class="preview" v-else>
        <img src="../assets/popup_icon_close.png" alt="" class="close" @click.stop="hideFile">
        <iframe :src="preview_url" frameborder="0"></iframe>
      </div>
    </div> -->
  </div>
</template>
<script>
  import Vue from 'vue'
  import WebIM from '../utils/WebIM';
  import emoji from "../config/emoji";
  // import pdf from "vue-pdf"
  import {
    LawyerServiceInfo,
    GetInfoByThirdIds,
    LawyerCommentList,
    ServiceInfo,
    CancelOrderCase,
    GetSaveNumber,
    TwoWayCall,
    GetCanCommentOrder,
    CommentInfo,
    AddComment,
    UpdateDocument,
    NoUpdateDocument,
    DirectionalOrder_ls,
    DirectionalOrder_writing,
    DirectionalOrder
  } from '@/api'
  import {
    mapGetters
  } from "vuex";
  var timer = null,
    timer_lawyer = null,
    differtimer = null
    var _timeOut=null,_timeindex=null,audios;
  export default {
    name: 'chatroom',
    // components:{
    //   pdf
    // },
    data() {
      return {
        counts: '00.00',
        type: '', //wait待接单正计时,chat聊天中,waitd 定向等待接单,finish:到期
        message: '',
        hoverEmo: false,
        hoverPic:false,
        hoverFile:false,
        dialogVisible: false,
        dialogVisibleWS: false,
        dialogVisibleLSH: false,
        dialogVisibleCase: false,
        dialogVisibleReason: false,
        dialogVisibleCall: false,
        dialogVisibleCallConfirm: false,
        dialogVisibleTip: false,
        dialogVisibleComment: false,
        dialogVisibleOrder: false,
        options: ['信息有误', '不想找律师', '仅咨询'],
        pjOrder: null,
        value: '',
        emojiObj: emoji.obj,
        email: '',
        pj: {
          msg: '',
          star: 5,
          tag: []
        },
        tag: [],
        showTime: false,
        lawyerNum: 0,
        lawyerInfo: {},
        user: null,
        finishTime: '',
        closeInput: false,
        canSend: false,
        chatMsg: [],
        showImg: false,
        url: '',
        showEmoji: false,
        dialogVal: {},
        newComment: {},
        commentGuid: '',
        orderList: [],
        tag1: [],
        tag2: [],
        tag3: [],
        tag4: [],
        last: '',
        showFile:false,
        preview_url:'',
        numPages:null,
        filetype:''
      }
    },
    watch: {
      chatType(newVal) {
        this.type = newVal
        this.getType()
      },
      lawyerThirdId(newVal) {
        // this.getInfo()
      },
      newMsg(newVal) {
        if (newVal.from == this.$store.state.Chat.lawyerThirdId) {
          var thisnoread = localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId +
            '_noreadnum')
          var noreadtotal = localStorage.getItem(this.user.MemberThirdId + '_noreadtotal')
          localStorage.setItem(this.user.MemberThirdId + '_noreadtotal', noreadtotal - thisnoread)
          this.$store.dispatch('onnoreadtotal', noreadtotal - thisnoread)
          localStorage.setItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId + '_noreadnum', 0)
          this.dealChat()
        } else if (newVal.from == this.user.MemberThirdId) {
          this.dealChat()
        }
        this.getServiceInfo()
      },

    },
    mounted() {
      this.initData();
      var that = this
      document.onkeydown = function (event) {
        var e = event || window.event;
        if (e && e.keyCode == 13) {
          that.sendFun()
        }
      }
     
    },
    methods: {
      cancelTip() {
        this.dialogVisibleCall = false
        _hmt.push(['_trackEvent', 'click', '购买电话咨询确认弹框-取消按钮', 'pc'])
      },
      cancelTel() {
        this.dialogVisibleCallConfirm = false
        _hmt.push(['_trackEvent', 'click', '电话咨询确认弹框-取消按钮', 'pc'])
      },
      initData() {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
        this.last = this.user.MemberMoblie.substring(7, 11)
        this.type = this.$store.state.Chat.chatType
        if (this.type == 'wait') {
          this.countFun()
        } else {
          this.$store.dispatch('onLawyerThirdId', localStorage.getItem('chatroomLawyer'))
          this.type = 'chat'
          this.getType()
          this.getServiceInfo()
        }
      },
      // getNumPages(){
      //   let loadingTask = pdf.createLoadingTask(this.preview_url)
      //   loadingTask.promise.then(pdf => {
      //     this.numPages = pdf.numPages
      //   }).catch(err => {
      //     console.error('pdf 加载失败', err);
      //   })
      // },
      getType() {
        // this.type = this.$store.state.Chat.chatType
        if (this.type != 'wait') {
          clearInterval(timer)
          clearInterval(timer_lawyer)
        }
        if (this.type == 'chat' || this.type == 'finish' || this.type == 'waitd') {
          var thisnoread = localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId +
            '_noreadnum')
          var noreadtotal = localStorage.getItem(this.user.MemberThirdId + '_noreadtotal')
          localStorage.setItem(this.user.MemberThirdId + '_noreadtotal', noreadtotal - thisnoread)
          this.$store.dispatch('onnoreadtotal', noreadtotal - thisnoread)
          localStorage.setItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId + '_noreadnum', 0)
          this.dealChat()
          this.getInfo()
        }
      },
      getInfo() {
        GetInfoByThirdIds({
          "thirdIds": this.$store.state.Chat.lawyerThirdId
        }).then(res => {
          if (res.state == 0) {
            this.lawyerInfo = res.data[0]
            LawyerServiceInfo({
              "lawyerGuid": res.data[0].MemberGuid
            }).then(res => {
              if (res.state == 0) {
                res.data.model.MemberRealName = res.data.model.MemberRealName.substring(0, 1) + '律师'
                res.data.model.ServiceCountMonth = res.data.model.ServiceCountMonth == 0 ? parseInt(Math
                  .random() * 100 + 100) : res.data.model.ServiceCountMonth
                res.data.model.Satisfaction = res.data.model.Satisfaction == '0%' ? '100%' : res.data.model
                  .Satisfaction
                this.lawyerInfo = res.data.model
                this.getTag()
              }
            })
            LawyerCommentList({
              "lawyerGuid": res.data[0].MemberGuid
            }).then(res => {
              if (res.state == 0) {
                if (res.data.length > 0) {
                  res.data[0].MemberMoblie_kh = res.data[0].MemberMoblie_kh.substring(0, 3) + '****' + res.data[
                    0].MemberMoblie_kh.substring(7, 11)
                  res.data[0].gray_star = 5 - res.data[0].Stars
                  // this.newComment = res.data[0]
                }
              }
            })
          }
        })

      },
      getServiceInfo() {
        if(differtimer!=null){
          clearInterval(differtimer)
          differtimer=null
        }
        var data = {
          "lawyerThirdId": this.$store.state.Chat.lawyerThirdId,
          "memberThirdId": this.user.MemberThirdId
        }
        ServiceInfo(data).then(res => {
          if (res.state == 0) {
            var FinishDate = res.data.FinishDate
            var CurrentTime = res.data.CurrentTime
            var f = new Date(FinishDate).getTime()
            var c = new Date(CurrentTime).getTime()
            var rest = f - c
            if (rest <= 0) { //到期
              this.finishTime = '服务已到期'
              this.closeInput = true
              this.type = 'finish'
              // this.$store.dispatch('onChatType', 'finish')
              if (res.data.isHavePendingstatements > 0) {
                this.type = 'waitd'
              }
            } else {
              this.closeInput = false
              this.type = 'chat'
              differtimer = setInterval(() => {
                if (rest < 1) {
                  this.finishTime = '服务已到期'
                  this.closeInput = true
                  // this.$store.dispatch('onChatType', 'finish')
                  this.type = 'finish'
                  clearInterval(differtimer)
                } else {
                  rest--;
                  if (rest < 3600) {
                    this.finishTime = this.formatSeconds(rest)
                  } else {
                    this.finishTime = this.timeRule(new Date(FinishDate.replace(/-/g, "/")))
                  }
                }
              }, 1000)
            }
          } else if (res.data.isHavePendingstatements > 0) {
            this.type = 'waitd'
          }
        })
      },
      dealChat() {
        var msg = localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId) ? JSON.parse(
          localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId)) : []
        if (msg.length > 0) {
          for (var i = 0; i < msg.length; i++) {
            msg[i].time = this.timeRule(new Date(parseInt(msg[i].time)))
            if (i == 0) {
              msg[i].showTime = true
            } else {
              if (msg[i].time == msg[i - 1].time) {
                msg[i].showTime = false
              } else {
                msg[i].showTime = true
              }
            }
            if (msg[i].msgType == 'text') {
              if (!msg[i].ext.ShowType) {
                msg[i].data = this.renderTxt(msg[i].data)
              }
            }else if(msg[i].msgType == 'audio'){
                if(i!=_timeindex){
                  msg[i].open=false
                }else{
                  msg[i].open=this.chatMsg[i].open?this.chatMsg[i].open:false
                }
            }else if(msg[i].msgType == 'file'){
                var index = msg[i].filename.lastIndexOf(".");
                  var filetype = (~index &&  msg[i].filename.slice(index + 1)) || "";
                  var icon=''
                  switch(filetype){
                      case 'doc':
                          icon='doc';
                          break;
                      case 'docx':
                          icon='doc';
                          break;
                      case 'xls':
                          icon='xls';
                          break;
                      case 'xlsx':
                          icon='xls';
                          break;
                      case 'pdf':
                          icon='pdf';
                          break;
                      case 'txt':
                          icon='txt';
                          break;
                      case 'ppt':
                          icon='ppt';
                          break;
                      case 'pptx':
                          icon='ppt';
                          break;
                  }
                  msg[i].icon=require('../assets/'+icon+'.png')
              }
          }
        }
        this.chatMsg = msg
        setTimeout(() => {
          var div = this.$refs.scroll.wrap;
          div.scrollTop = div.scrollHeight;
        }, 500)
      },
      playAudio(url,length,index){
          var that=this
          if(_timeOut!=null){
              clearTimeout(_timeOut)
              _timeOut=null
              audios.pause()
              that.chatMsg[_timeindex].open=false
              if(_timeindex==index){
                  return;
              }else{
              }
          }
          let options = {
              url: url,
              headers: {
                  Accept: 'audio/mp3'
              },
              onFileDownloadComplete: function (response) {
                  let objectUrl = WebIM.utils.parseDownloadResponse.call(WebIM.conn, response)
                  audios = document.getElementById("audio_id")
                  _timeindex=index
                  audios.src = objectUrl
                  audios.load()
                  audios.play()
                  that.chatMsg[index].open=true
                  console.log(that.chatMsg[index].open)
                  _timeOut = setTimeout(() => {
                      that.chatMsg[index].open=false
                      clearTimeout(_timeOut)
                      _timeOut=null
                  }, length*1000)
              },
              onFileDownloadError: function () {}
          }
          WebIM.utils.download.call(WebIM.conn, options)
      },
      renderTxt(txt = "") {
        let rnTxt = [];
        let match = null;
        const regex = /(\[.*?\])/g;
        let start = 0;
        let index = 0;
        while ((match = regex.exec(txt))) {
          index = match.index;
          if (index > start) {
            rnTxt.push(txt.substring(start, index));
          }
          if (match[1] in this.emojiObj) {
            const v = this.emojiObj[match[1]];
            var edata = '<img  src="' + require(`../assets/faces/` + v) + '" alt="" class="emo"/>'
            rnTxt.push(edata);
          } else {
            rnTxt.push(match[1]);
          }
          start = index + match[1].length;
        }
        rnTxt.push(txt.substring(start, txt.length));
        var rn = ''
        for (var i = 0; i < rnTxt.length; i++) {
          rn += rnTxt[i]
        }
        return rn
      },
      wsChange(index) {
        if (!this.chatMsg[index].ext.isChange) {
          var data = {
            "memberThirdId": this.user.MemberThirdId,
            "lawyerThirdId": this.$store.state.Chat.lawyerThirdId,
            "orderGuid": this.chatMsg[index].ext.OrderGuid
          }
          UpdateDocument(data).then(res => {
            if (res.state == 0) {
              this.chatMsg[index].ext.isChange = true
            } else {
              this.$message(res.des)
            }
          })
        }

      },
      noWsChange(index) {
        var data = {
          "memberThirdId": this.user.MemberThirdId,
          "lawyerThirdId": this.$store.state.Chat.lawyerThirdId,
          "orderGuid": this.chatMsg[index].ext.OrderGuid
        }
        NoUpdateDocument(data).then(res => {
          if (res.state == 0) {
            this.chatMsg[index].ext.isChange = true
          } else {
            this.$message(res.des)
          }
        })
      },
      addComment() {
        _hmt.push(['_trackEvent', 'click', '提交评价按钮', 'pc']);
        if (this.pj.tag.length == 0) {
          this.$message('请选择1-3个印象标签')
        } else {
          var data = {
            "stars": this.pj.star,
            "commentDes": this.pj.msg == '' ? '暂无文字评价' : this.pj.msg,
            "orderGuid": this.commentGuid,
            "memberGuid": this.user.MemberGuid,
            "lawyerGuid": this.lawyerInfo.MemberGuid,
            "impressIDs": this.pj.tag.join(',')
          }
          AddComment(data).then(res => {
            if (res.state == 0) {
              this.dialogVisible = true
              this.pjOrder = null
              this.commentGuid = ''
            } else {
              this.$message(res.des)
            }
          })
        }
      },
      closePJ() {
        this.dialogVisibleComment = false
        this.dialogVisibleOrder = false
        this.dialogVisible = false
      },
      comment(index) {
        _hmt.push(['_trackEvent', 'click', '去评价', 'pc'])

        if (index == 'all') {
          var data = {
            "memberGuid": this.user.MemberGuid,
            "lawyerID": this.lawyerInfo.MemberGuid,
          }
          GetCanCommentOrder(data).then(res => {
            if (res.state == 0) {
              if (res.data.length == 1) {
                this.commentGuid = res.data[0].OrderGuid
                this.pj.star = 5
                this.pj.tag = []
                this.pj.msg = ''
                this.dialogVisibleComment = true
              } else if (res.data.length > 1) {
                this.orderList = res.data
                this.dialogVisibleOrder = true
              } else {
                this.$message('暂无可评价订单')
              }
            } else {
              this.$message(res.des)
            }
          })
        } else {
          this.commentGuid = this.chatMsg[index].ext.OrderGuid
          this.pj.star = 5
          this.pj.tag = []
          this.pj.msg = ''
          this.dialogVisibleComment = true
        }

      },
      getTag() {
        var data = {
          "memberGuid": this.user.MemberGuid,
          "lawyerGuid": this.lawyerInfo.MemberGuid
        }
        CommentInfo(data).then(res => {
          if (res.state == 0) {
            var impress = res.data.Impress
            for (var i = 0; i < impress.length; i++) {
              if (impress[i].IsPraise == 1) {
                this.tag1.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 2) {
                this.tag2.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 3) {
                this.tag3.push(impress[i].ImpressName)
              }
              if (impress[i].IsPraise == 4) {
                this.tag4.push(impress[i].ImpressName)
              }
            }
            this.tag = this.getArrayItems(this.tag3, 6)
          }
        })
      },
      getArrayItems: function (arr, num) {
        //新建一个数组,将传入的数组复制过来,用于运算,而不要直接操作传入的数组;
        var temp_array = new Array();
        for (var index in arr) {
          temp_array.push(arr[index]);
        }
        //取出的数值项,保存在此数组
        var return_array = new Array();
        for (var i = 0; i < num; i++) {
          //判断如果数组还有可以取出的元素,以防下标越界
          if (temp_array.length > 0) {
            //在数组中产生一个随机索引
            var arrIndex = Math.floor(Math.random() * temp_array.length);
            //将此随机索引的对应的数组元素值复制出来
            return_array[i] = temp_array[arrIndex];
            //然后删掉此索引的数组元素,这时候temp_array变为新的数组
            temp_array.splice(arrIndex, 1);
          } else {
            //数组中数据项取完后,退出循环,比如数组本来只有10项,但要求取出20项.
            break;
          }
        }
        return return_array;
      },
      nextStep() {
        this.pj.star = 5
        this.pj.tag = []
        this.pj.msg = ''
        if (this.pjOrder != null) {
          this.dialogVisibleComment = true
        } else {
          this.$message('请选择评价订单')
        }
      },
      goPhone() {
        _hmt.push(['_trackEvent', 'click', '电话按钮咨询', 'pc']);
        var data = {
          "memberGuid": this.user.MemberGuid,
          "lawyerGuid": this.lawyerInfo.MemberGuid,
          "from": "kh"
        }
        GetSaveNumber(data).then(res => {
          if (res.state == -1) {
            if (res.des == '未找到电话订单') {
              this.dialogVisibleCall = true
            } else {
              this.$message(res.des)
            }
          } else {
            this.dialogVisibleCallConfirm = true
          }
        })
      },
      call() {
        _hmt.push(['_trackEvent', 'click', '电话咨询确认弹框-确定按钮', 'pc']);
        var data = {
          "memberGuid": this.user.MemberGuid,
          "lawyerGuid": this.lawyerInfo.MemberGuid
        }
        TwoWayCall(data).then(res => {
          if (res.state == 0) {
            this.dialogVisibleTip = true
          } else {
            this.$message(res.des)
          }
        })
      },
      buyCall() {
        _hmt.push(['_trackEvent', 'click', '购买电话咨询确认弹框-立即购买', 'pc'])
        //下单收银台
        var data = {
          "questionType": "",
          "description": "",
          "memberGuid": this.user.MemberGuid,
          "lawyerGuid": this.lawyerInfo.MemberGuid,
          "productGuid": "A544A19A-3E9B-497B-95E3-AB6519B0B76A",
          "orderFrom": localStorage.getItem('orderfrom'),
          "remark": ""
        }
        var loading = this.$loading();
        DirectionalOrder(data).then(res => {
          loading.close();
          if (res.state == 0) {
            localStorage.setItem('payOrder', JSON.stringify(res.data))
            this.$router.push('/cashier')
          } else {
            this.$message(res.des)
          }
        })
      },
      goIndex() {
        this.$router.push('/index')
      },
      toPay(index) {
        //下单后跳转收银台电话咨询、视频咨询，进入对应【收银台】页
        var ext = this.chatMsg[index].ext
        this.dialogVal = this.chatMsg[index].ext
        if (ext.ShowType == 'PushDirectService') {
          if (ext.ProductTitle == '文书代写') {
            this.dialogVisibleWS = true
            this.email = this.user.MemberEmail == null ? '' : this.user.MemberEmail
          } else if (ext.ProductTitle == '律师函') {
            this.dialogVisibleLSH = true
          } else {
            //下单进入对应【收银台】页
            var data = {
              "questionType": "",
              "description": "",
              "memberGuid": this.user.MemberGuid,
              "lawyerGuid": this.lawyerInfo.MemberGuid,
              "productGuid": ext.ProductGuid,
              "orderFrom": localStorage.getItem('orderfrom'),
              "remark": ""
            }
            var loading = this.$loading();
            DirectionalOrder_ls(data).then(res => {
              loading.close();
              if (res.state == 0) {
                localStorage.setItem('payOrder', JSON.stringify(res.data))
                this.$router.push('/cashier')
              } else {
                this.$message(res.des)
              }
            })
          }
        } else if (ext.ShowType == 'ShowCase') {
          this.dialogVisibleCase = true
        }

      },
      toBuy(type) {
        var reg = new RegExp("^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$"); //正则表达式
        if (!reg.test(this.email)) { //默认显示个人资料里的邮箱，允许修改
          this.$message('请填写正确邮箱地址')
        } else {
          //下单跳收银台
          var data = {
            "orderGuid": this.dialogVal.OrderGuid,
            "memberGuid": this.user.MemberGuid,
            "lawyerGuid": this.lawyerInfo.MemberGuid,
            "productGuid": this.dialogVal.ProductGuid,
            "orderFrom": localStorage.getItem('orderfrom'),
            "remark": this.dialogVal.Remark,
            "description": this.dialogVal.Description,
            "money": this.dialogVal.money,
            "linkEmail": this.email,
            "layefinish": this.dialogVal.Layefinish,
          }
          var loading = this.$loading();
          DirectionalOrder_writing(data).then(res => {
            loading.close();
            if (res.state == 0) {
              localStorage.setItem('payOrder', JSON.stringify(res.data))
              this.$router.push('/cashier')
            } else {
              this.$message(res.des)
            }
          })
        }
      },
      cancelCase() {
        this.dialogVisibleReason = true
      },
      confirm() {
        if (this.value == '') {
          this.$message('请选择取消原因')
        } else {
          var data = {
            "caseGuid": this.dialogVal.CaseGuid,
            "remark": this.value
          }
          CancelOrderCase(data).then(res => {
            if (res.state == 0) {
              this.$message('已取消')
              setTimeout(() => {
                this.dialogVisibleReason = false
                this.dialogVisibleCase = false
              }, 1000)
            } else {
              this.$message(res.des)
            }
          })
        }
      },
      confirmCase() {
        //收银台
        this.$router.push('cashier?CaseGuid=' + this.dialogVal.CaseGuid)
      },
      selectEmoji(i) {
        this.message = this.message + i
        this.canSend = true
      },
      bigImg(url) {
        this.url = url
        var that = this;
        that.showImg = true
        let img = new Image()
        img.src = that.url
        img.onload = function () {
          if (img.height >= img.width) {
            document.getElementById('bigimg').style.width = 'auto'
            document.getElementById('bigimg').style.height = (window.innerHeight - 44) * 0.8 + 'px'
            document.getElementById('bigimg').style.marginLeft = -(img.width * (window.innerHeight - 44) *
              0.8 / img
              .height) / 2 + 'px'
            document.getElementById('bigimg').style.marginTop = -((window.innerHeight - 44) * 0.8) / 2 + 'px'
          } else {
            document.getElementById('bigimg').style.width = window.innerWidth * 0.8 + 'px'
            document.getElementById('bigimg').style.height = 'auto'
            document.getElementById('bigimg').style.marginLeft = -(window.innerWidth * 0.8) / 2 + 'px'
            document.getElementById('bigimg').style.marginTop = -(img.height * (window.innerWidth * 0.8) /
                img.width) / 2 +
              'px'
          }

        }

      },
      hideimg() {
        this.showImg = false
      },
      emoFun() { //表情
        console.log(1)
        if (this.finishTime != '服务已到期') {
          this.showEmoji = true
        }else{
          this.$message('服务已到期')
        }
      },
      pictureChange(e) {
                console.log(e)
                if (this.finishTime == '服务已到期') {
                  this.$message('服务已到期')
                  document.getElementById('imgDom').value = ''
                  return;
                }
                var that = this;
                var file = WebIM.utils.getFileUrl(e.target);
                var chatMsg = this.chatMsg
                var id = WebIM.conn.getUniqueId(); // 生成本地消息id
                var msg = new WebIM.message('img', id); // 创建图片消息
                var imgurl=''
                msg.set({
                    file: file,
                    to: this.lawyerInfo.MemberThirdId,
                    chatType: 'singleChat',
                    onFileUploadComplete(res){
                      console.log(res)
                      imgurl=res.url
                    },
                    success: function (res, serverMsgId) {
                        console.log("图片发送成功", serverMsgId);
                        var reads = new FileReader();
                        var f = document.getElementById('imgDom').files[0]
                        reads.readAsDataURL(f)
                        reads.onload = function (e) {
                            var data = {
                                contentsType: "IMAGE",
                                data: '',
                                error: false,
                                errorCode: 0,
                                errorText: "",
                                ext: {},
                                from: that.user.MemberThirdId,
                                id: serverMsgId,
                                msgConfig: null,
                                msgType: "picture",
                                sourceMsg: '',
                                time: new Date().getTime(),
                                to: that.lawyerInfo.MemberThirdId,
                                type: "chat",
                                url: imgurl
                            }
                            chatMsg.push(data)
                            localStorage.setItem(that.user.MemberThirdId + that.lawyerInfo
                                .MemberThirdId, JSON.stringify(chatMsg))
                            document.getElementById('imgDom').value = ''
                            that.dealChat()
                        }

                    }
                })
                WebIM.conn.send(msg.body);
            },
      fileChange(e){
            if (this.finishTime == '服务已到期') {
                  this.$message('服务已到期')
                  document.getElementById('fileDom').value = ''
                  return;
                }
            console.log(e)
            const loading =this.$loading({
              lock: true,
              text: '',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.7)'
              });
          var that = this;
          var file = WebIM.utils.getFileUrl(e.target);
          var index = file.filename.lastIndexOf(".");
          var filetype = (~index && file.filename.slice(index + 1)) || "";
          if(filetype!='docx'&&filetype!='doc'&&filetype!='xls'&&filetype!='xlsx'&&filetype!='pdf'&&filetype!='txt'&&filetype!='ppt'&&filetype!='pptx'){
            loading.close();
            this.$message('限发送word/excel/pdf/PPT/txt文件')
            document.getElementById('fileDom').value = ''
            return;
          }
          if(file.data.size/1024/1024>10){
            loading.close();
              this.$message({
                  message:'文件过大，请选择10M内文件',
                  type:'warning'
              })
              document.getElementById('fileDom').value = ''
              return
          }
          var chatMsg = this.chatMsg
          var id = WebIM.conn.getUniqueId(); // 生成本地消息id
          var msg = new WebIM.message('file', id); // 创建图片消息
          var fileurl=''
          msg.set({
              file: file,
              to: this.lawyerInfo.MemberThirdId,
              chatType: 'singleChat',
              onFileUploadComplete(res){
                console.log(res)
                fileurl=res.url
              },
              success: function (res, serverMsgId) {
                  console.log("文件发送成功", serverMsgId);
                  var reads = new FileReader();
                  var f = document.getElementById('fileDom').files[0]
                  reads.readAsDataURL(f)
                  reads.onload = function (e) {
                      loading.close();
                      console.log(f)
                      var index =f.name.lastIndexOf(".");
                      var filetype = (~index &&  f.name.slice(index + 1)) || "";
                      var icon=''
                      switch(filetype){
                          case 'doc':
                              icon='doc';
                              break;
                          case 'docx':
                              icon='doc';
                              break;
                          case 'xls':
                              icon='xls';
                              break;
                          case 'xlsx':
                              icon='xls';
                              break;
                          case 'pdf':
                              icon='pdf';
                              break;
                          case 'txt':
                              icon='txt';
                              break;
                          case 'ppt':
                              icon='ppt';
                              break;
                          case 'pptx':
                              icon='ppt';
                              break;
                      }
                      var data = {
                          contentsType: "FILE",
                          data: '',
                          error: false,
                          errorCode: 0,
                          errorText: "",
                          ext: {},
                          from: that.user.MemberThirdId,
                          id: serverMsgId,
                          msgConfig: null,
                          msgType: "file",
                          sourceMsg: '',
                          time: new Date().getTime(),
                          to: that.lawyerInfo.MemberThirdId,
                          type: "chat",
                          url: fileurl,
                          filename:f.name,
                          icon:require('../assets/'+icon+'.png')
                      }
                      chatMsg.push(data)
                      localStorage.setItem(that.user.MemberThirdId + that.lawyerInfo
                          .MemberThirdId, JSON.stringify(chatMsg))
                      document.getElementById('fileDom').value = ''
                      that.dealChat()
                  }

              }
          })
          WebIM.conn.send(msg.body);
      },
      previewFile(url,name){
         var index =name.lastIndexOf(".");
         var filetype = (~index &&  name.slice(index + 1)) || "";
        //  this.filetype=filetype
        // if(filetype=='pdf'){
        //   this.preview_url=url
        //    this.getNumPages()
        // }else if(filetype=='txt'){
        //   let xhr = new XMLHttpRequest()
        //   xhr.open('GET', url, false);// 文件路径
        //   xhr.overrideMimeType("text/html;charset=utf-8");// 默认为utf-8
        //   xhr.send(null);
        //   this.preview_url=xhr.responseText
        // }else{
        //   this.preview_url='https://view.officeapps.live.com/op/view.aspx?src='+url
        // }
        //   this.showFile=true
        if(filetype=='pdf'||filetype=='txt'){
          window.open(url)
        }else{
          if(url.indexOf('https')<0){
            url='https'+url.substring(4,url.length)
          }
          window.open('https://view.officeapps.live.com/op/view.aspx?src='+url)
        }
        
      },
      hideFile(){
       this.showFile=false
       this.preview_url=''
      },
      hideModal() {
        this.showEmoji = false

      },
      msgInput(val) {
        this.canSend = this.isNull(val)? false : true
      },
       isNull( str ){

        if ( str == "" ) return true;

        var regu = "^[ ]+$";

        var re = new RegExp(regu);

        return re.test(str);

        },
      sendFun() {
        var that = this;
        if (this.canSend) {
          let id = WebIM.conn.getUniqueId(); // 生成本地消息id
          let msg = new WebIM.message('txt', id); // 创建文本消息
          var chatMsg = localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId) ? JSON
            .parse(localStorage.getItem(this.user.MemberThirdId + this.$store.state.Chat.lawyerThirdId)) : []
          msg.set({
            msg: this.message, // 消息内容
            to: this.$store.state.Chat.lawyerThirdId, // 接收消息对象（用户id）
            chatType: 'singleChat', // 设置为单聊
            success: function (res, serverMsgId) {
              console.log('send private text Success', serverMsgId);
              var data = {
                contentsType: "TEXT",
                data: that.message,
                error: false,
                errorCode: 0,
                errorText: "",
                ext: {},
                from: that.user.MemberThirdId,
                id: serverMsgId,
                msgConfig: null,
                msgType: "text",
                sourceMsg: that.message,
                time: new Date().getTime(),
                to: that.$store.state.Chat.lawyerThirdId,
                type: "chat"
              }
              chatMsg.push(data)
              localStorage.setItem(that.user.MemberThirdId + that.$store.state.Chat.lawyerThirdId, JSON.stringify(
                chatMsg))
              that.dealChat()
              that.message = ''
              that.canSend = false
            },
            fail: function (e) {
              console.log(e)
              // 失败原因:
              // e.type === '603' 被禁言
              // e.type === '605' 群组不存在
              // e.type === '602' 不在群组或聊天室中
              // e.type === '504' 撤回消息时超出撤回时间
              // e.type === '505' 未开通消息撤回
              // e.type === '506' 没有在群组或聊天室白名单
              // e.type === '501' 消息包含敏感词
              // e.type === '502' 被设置的自定义拦截捕获
              // e.type === '503' 未知错误
              console.log("Send private text error");
              Vue.prototype.loginIM()

            }
          });
          WebIM.conn.send(msg.body);
        }
      },
      countFun() {
        var counts = Number(this.counts),
          that = this,
          str = '00.00'
        timer = setInterval(() => {
          counts = (Number(counts) + 0.01).toFixed(2)
          if (counts >= 60) {
            clearInterval(timer)
            clearInterval(timer_lawyer)
            str = '60.00'
          } else {
            if (counts < 10) {
              str = '0' + counts
            } else {
              str = counts
            }
          }
          that.counts = str
        }, 10)
        var lawyerNum = this.lawyerNum
        timer_lawyer = setInterval(() => {
          lawyerNum = lawyerNum + parseInt(Math.random() * 3 + 1)
          that.lawyerNum = lawyerNum
        }, 1000)
      },
     
      choseOrder(index) {
        if (this.pjOrder == index) {
          this.pjOrder = null
          this.commentGuid = ''
        } else {
          this.pjOrder = index
          this.commentGuid = this.orderList[index].OrderGuid
        }
      },
      starFun(index) {
        this.pj.tag = []
        this.pj.star = index + 1
        //IsPraise:1男；2女；3通用；4通用坏
        //1-2星：4；3-4-5星：性别x3+通用x3
        if (this.pj.star == 1 || this.pj.star == 2) {
          this.tag = this.getArrayItems(this.tag4, 6)
        } else if (this.pj.star == 3 || this.pj.star == 4 || this.pj.star == 5) {
          var arr1 = this.getArrayItems(this.tag3, 3),
            arr2 = []
          if (this.lawyerInfo.MemberSex == '0858fad1-30d9-4abe-9d72-867f907bb8ad') {
            arr2 = this.getArrayItems(this.tag2, 3)
          } else {
            arr2 = this.getArrayItems(this.tag1, 3)
          }
          this.tag = [...arr1, ...arr2];
        }
      },
      tagFun(index) {
        if (this.pj.tag.length < 3 && this.pj.tag.indexOf(this.tag[index]) < 0) {
          this.pj.tag.push(this.tag[index])
        } else if (this.pj.tag.indexOf(this.tag[index]) >= 0) {
          this.pj.tag.splice(this.pj.tag.indexOf(this.tag[index]), 1)
        } else if (this.pj.tag.length >= 3) {
          this.$message('印象标签最多选三个')
        }
        console.log(this.pj.tag)
      },
      formatSeconds(value) {
        var theTime = parseInt(value); // 秒
        var theTime1 = 0; // 分
        var theTime2 = 0; // 小时
        if (theTime > 60) {
          theTime1 = parseInt(theTime / 60);
          theTime = parseInt(theTime % 60);
          if (theTime1 > 60) {
            theTime2 = parseInt(theTime1 / 60);
            theTime1 = parseInt(theTime1 % 60);
          }
        }
        var result = parseInt(theTime) < 10 ? '0' + theTime : theTime;
        if (theTime1 > 0) {
          result = (parseInt(theTime1) < 10 ? '0' + theTime1 : theTime1) + ":" + result;
        }
        if (theTime2 > 0) {
          result = (parseInt(theTime2) < 10 ? '0' + theTime2 : theTime2) + ":" + result;
        }
        if (theTime1 <= 0 && theTime2 <= 0)
          return result + '秒';
        else
          return result;
      },
      timeRule(date) {
        var month = date.getMonth() + 1;
        month = month >= 10 ? month : '0' + month;
        var day = date.getDate();
        day = day >= 10 ? day : '0' + day;
        var hour = date.getHours();
        hour = hour >= 10 ? hour : '0' + hour;
        var minute = date.getMinutes();
        minute = minute >= 10 ? minute : '0' + minute;
        return month + '-' + day + ' ' + hour + ':' + minute
      }
    },
    computed: {
      ...mapGetters({
        lawyerThirdId: 'onLawyerThirdId',
        chatType: 'onChatType',
        newMsg: 'onNewMsg'
      })
    }
  }

</script>

<style scoped lang="scss">
  .header {
    width: 940px;
    height: 40px;
    margin: 0 auto;
    line-height: 40px;
    position: relative;

    .left {
      font-size: 14px;
      color: #666666;

      span,
      img {
        margin-right: 10px;
        vertical-align: middle;
      }
    }

    .right {
      position: absolute;
      right: 0;
      top: 0px;

      img {
        vertical-align: middle;
        margin-right: 8px;
      }

      span {
        vertical-align: middle;
        font-size: 16px;
        font-weight: bold;
        color: #21D0C8;
      }

      .work {
        position: absolute;
        top: 40px;
        left: 19px;
        width: 110px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: #666666;
        text-align: center;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
        border-radius: 10px;
        z-index: 999;
      }
    }
  }

  .main {
    width: 100%;
    height: calc(100vh - 40px);
    position: absolute;
    top: 40px;
    left: 0;
    background: url('../assets/messages_bg.png') no-repeat;
    background-size: 100% 100%;
  }

  .main_in_wait {
    width: 940px;
    height: 570px;
    background: #FFFFFF url('../assets/messages_img_count.png') no-repeat;
    background-size: 100% 300px;
    box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.2000);
    border-radius: 10px;
    position: absolute;
    top: 50%;
    margin-top: -285px;
    left: 50%;
    margin-left: -470px;

    .main_info {
      padding-top: 174px;
      text-align: center;

      >div:nth-child(1) {
        font-size: 18px;
        font-weight: bold;
        color: #FFFFFF;
        margin-bottom: 26px;
      }

      .time {
        width: 400px;
        height: 160px;
        background: #FFFFFF;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1000);
        border-radius: 20px;
        margin: 0 auto 42px;
        font-size: 100px;
        font-weight: bold;
        color: #2E8CF1;
        text-align: left;
        padding: 0 58px;
        box-sizing: border-box;

        span {
          font-size: 72px;
          font-weight: 500;
        }

        line-height: 160px;
      }

      >div:nth-child(3) {
        font-size: 36px;
        font-weight: bold;
        color: #333333;
      }

      >div:nth-child(4) {
        font-size: 18px;
        color: #666666;
      }
    }
  }

  .main_in_chat {
    width: 940px;
    height: 570px;
    position: absolute;
    top: 50%;
    margin-top: -285px;
    left: 50%;
    margin-left: -470px;

    .left {
      width: 640px;
      height: 570px;
      background: #E9EEF4;
      box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.2000);
      border-radius: 10px;
      margin-right: 20px;
      display: inline-block;
      vertical-align: top;

      // overflow: hidden;
      .server {
        width: 640px;
        height: 40px;
        background: #E9EEF4;
        box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.2000);
        border-radius: 10px 10px 0px 0px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        color: #333333;
      }

      .chat {
        width: 640px;
        height: 360px;
        // overflow: auto;
        // padding: 0 20px;
        box-sizing: border-box;

        .chat_in {
          margin-bottom: 20px;
        }

        .chat_in>div>div>div {
          display: inline-block;
          vertical-align: middle;
        }

        .chat_in>div>div {
          margin-bottom: 20px !important;
        }

        .pic {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          vertical-align: top;
        }

        .time {
          text-align: center;
          margin: 20px;
          font-size: 16px;
          color: #999999;
        }

        .user {
          text-align: right;
          padding: 0 20px;

          .state {
            margin-right: 19px;
          }

          .word {
            background: #21D0C8;
            border-radius: 15px 0px 15px 15px;
            margin-right: 20px;

          }

          .word::after {
            content: "";
            position: absolute;
            top: 0;
            right: -14px;
            width: 0px;
            height: 0px;
            background: #21D0C8;
            border: solid 7px;
            border-top: solid 13px;
            border-bottom: solid 13px;
            border-color: #21D0C8 #E9EEF4 #E9EEF4 #21D0C8;
          }
          .file{
            background: #fff;
            color: #333;
            cursor: pointer;
            .name{
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              max-width: 300px;
              overflow: hidden;
              display: -webkit-inline-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
            }
            img{
              vertical-align: middle;
            }
          }
          .file::after{
            background: #fff;
            border-color: #fff #E9EEF4 #E9EEF4 #fff;
          }
        }

        .lawyer {
          padding: 0 20px;

          .state {
            margin-left: 19px;
          }

          .word {
            background: #fff;
            border-radius: 0px 15px 15px 15px;
            margin-left: 20px;
            color: #333;
          }

          .word::before {
            content: "";
            position: absolute;
            top: 0;
            left: -14px;
            width: 0px;
            height: 0px;
            background: #fff;
            border: solid 7px;
            border-top: solid 13px;
            border-bottom: solid 13px;
            border-color: #fff #fff #E9EEF4 #E9EEF4;
          }
          .file{
            cursor: pointer;
            .name{
              display: inline-block;
              vertical-align: middle;
              margin-right: 10px;
              max-width: 300px;
              overflow: hidden;
              display: -webkit-inline-box;
              -webkit-box-orient:vertical;
              -webkit-line-clamp:2;
            }
            img{
              vertical-align: middle;
            }
          }
        }

        .state {
          font-size: 16px;
          color: #626262;
        }

        .word {
          padding: 16px;
          font-size: 20px;
          color: #FFFFFF;
          line-height: 28px;
          max-width: 400px;
          position: relative;
          word-break: break-all;
          text-align: left;

          img {
            max-width: inherit;
          }
        }

        .chat_in .normal {
          width: 320px;
          margin: 0 auto;
          border-radius: 8px;
          background: #fff;

          >div {
            display: block;
          }

          .title {
            border-radius: 10px 10px 0px 0px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 18px;
            color: #fff;
          }

          .blue {
            background: #0072E6;
          }

          .orange {
            background: #FF9900;
          }

          .green {
            background: #35AF38;
          }

          .center {
            padding: 20px;
            font-size: 18px;
            color: #808080;
            text-align: left;

            span {
              color: #333;
            }

            >div:nth-child(2) {
              margin-top: 10px;
            }
          }

          .btn {
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 18px;
            color: #333333;
            cursor: pointer;
          }

          .btn2 {
            display: flex;

            >div {
              display: inline-block;
              flex: 1;
            }

            >div:nth-child(1) {
              border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
          }
        }

        .tip {
          width: 320px;
          margin: 0 auto;
          border-radius: 8px;
          background: #fff;
          box-sizing: border-box;
          padding: 18px 27px;
          font-size: 18px;
          color: #666666;
          line-height: 24px;
        }
      }

      .foot {
        width: 640px;
        height: 170px;
        background: #FFFFFF;
        border-radius: 0px 0px 10px 10px;
        box-sizing: border-box;
        padding: 9px 20px 0;
        position: relative;

        .emo_icon {
          margin-left: 10px;
          margin-bottom: 2px;
          width: 26px;
          height: 26px;
        }
        .pic_div{
          display: inline-block;
          position: relative;
          margin-left: 16px;
          margin-bottom: 2px;
          width: 26px;
          height: 26px;
          img{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 26px;
            height: 26px;
          }
          input{
            width: 26px;
            height: 26px;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            opacity: 0;
          }
        }
        .send {
          width: 80px;
          height: 28px;
          background: #E9EEF4;
          border-radius: 6px;
          text-align: center;
          line-height: 28px;
          color: #fff;
          font-size: 16px;
          float: right;
          cursor: pointer;
        }

        .send.active {
          background: #00C8B4;
        }

        .send.active:hover {
          background: #33D3C3;
        }
      }
    }

    .right {
      width: 280px;
      height: 570px;
      background: url('../assets/messages_img_lawyer.png') no-repeat;
      background-size: 100% 100%;
      box-shadow: 0px 0px 16px 0px rgba(4, 0, 0, 0.2000);
      border-radius: 13px;
      display: inline-block;
      box-sizing: border-box;
      padding: 30px 18px;
      border: 2px solid #FFFFFF;

      .top {
        margin-bottom: 15px;

        >img {
          width: 81px;
          height: 81px;
          background: #FFFFFF;
          border: 2px solid #FFFFFF;
          border-radius: 50%;
          margin-right: 9px;
          vertical-align: middle;
        }

        .name {
          display: inline-block;
          position: relative;
          vertical-align: middle;

          >div:nth-child(1) {
            font-size: 24px;
            color: #333333;
            margin-bottom: 2px;
          }

          >div:nth-child(2) {
            font-size: 18px;
            color: #666666;
          }

          >div:nth-child(3) {
            width: 70px;
            height: 20px;
            background: #0A0E22;
            border-radius: 10px 10px 10px 0px;
            font-size: 12px;
            color: #FFFFFF;
            text-align: center;
            line-height: 20px;
            position: absolute;
            top: -10px;
            left: 80px;
          }
        }
      }

      .good {
        font-size: 16px;
        color: #333333;
        margin-bottom: 15px;
      }

      .thrity {
        font-size: 14px;
        color: #666666;
        margin-bottom: 14px;
      }

      .three {
        margin-bottom: 15px;

        .item {
          font-size: 16px;
          color: #333333;
          display: inline-block;
          vertical-align: middle;
          margin-right: 36px;

          img {
            // margin-bottom: 8px;
          }
        }

        .item:last-child {
          margin-right: 0;
        }
      }

      .pj {
        >div:nth-child(1) {
          font-size: 14px;
          color: #666666;
          margin-bottom: 10px;
        }

        >div:nth-child(2) {
          margin-bottom: 6px;
          font-size: 14px;
          color: #333333;

          .star {
            display: inline-block;
            margin-left: 10px;

            img {
              margin-right: 3px;
              width: 12px;
              height: 12px;
            }
          }
        }

        >div:nth-child(3) {
          font-size: 16px;
          color: #333333;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 6px;
        }

        >div:nth-child(4) {
          font-size: 14px;
          color: #666666;
        }
      }

      .tip {
        width: 200px;
        height: 36px;
        background: #E9EEF4;
        border-radius: 18px;
        font-size: 16px;
        color: #333333;
        text-align: center;
        line-height: 36px;
        margin: 40px auto 0;
      }

      .btn {
        margin: 40px auto 0;
        width: 200px;

        >div {
          width: 200px;
          height: 36px;
          border-radius: 18px;
          font-size: 16px;
          text-align: center;
          line-height: 36px;
          color: #666666;
          cursor: pointer;
        }

        >div:nth-child(1) {
          background: #00C8B4;
          color: #FFFFFF;
          margin-bottom: 10px;
        }

        >div:nth-child(1):hover {
          background: #33D3C3;
        }
      }

      .scan {
        margin-top: 20px;
        font-size: 14px;
        color: #333333;

        >div {
          display: inline-block;
          vertical-align: middle;
        }

        .er {
          width: 150px;
          height: 150px;
          background: #FFFFFF;
          border-radius: 20px;
          box-sizing: border-box;
          // padding: 5px;
          margin-right: 3px;

          img {
            width: 100%;
            border-radius: 20px;
          }
        }

        >div:nth-child(2) {
          >div:nth-child(1) {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .preview{
    position: fixed;
    width: 600px;
    height: 800px;
    top: 50%;
    left: 50%;
    margin-left: -300px;
    margin-top: -400px;
    z-index: 999;
    background: #fff;
    iframe{
      width: 600px;
      height: 800px;
    }
    .close{
      position: absolute;
      top: -35px;
      right: -35px;
    }
    pre{
       width: 600px;
    height: 800px;
    overflow: auto;
    }
  }

  .big {
    position: fixed;
    width: 500px;
    top: 100px;
    left: 50%;
    margin-left: -250px;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0);
    height: 80%;

    img {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 500px;
    }
  }

  .emojiDiv {
    width: 384px;
    padding: 20px;
    box-shadow: 0 0 20px #ccc;
    position: absolute;
    top: -155px;
    left: 7px;
    z-index: 999;
    background: #fff;

  }

  .wenshu {
    .close {
      position: absolute;
      top: 0;
      right: 0;
    }

    .title {
      text-align: center;
      font-size: 18px;
      color: #333333;
      margin-bottom: 28px;
    }

    .info {
      .item {
        margin-bottom: 10px;
        display: flex;

        >div {
          display: inline-block;
          vertical-align: top;
          font-size: 14px;
          color: #666666;
        }

        >div:nth-child(1) {
          width: 70px;
        }

        >div:nth-child(2) {
          color: #aaa;
          margin-left: 20px;
          flex: 1;
        }
      }
    }

    .btn {
      width: 160px;
      height: 40px;
      background: #00C8B4;
      border-radius: 20px;
      margin: 28px auto 0;
      font-size: 18px;
      color: #FFFFFF;
      text-align: center;
      line-height: 40px;
      ;
      cursor: pointer;
    }

    .mintip {
      font-size: 18px;
      color: #666666;
      width: 216px;
      margin: 40px auto 40px;
      text-align: center;
    }

    .webtip {
      font-size: 18px;
      color: #666666;
      margin: 40px auto 10px;
      text-align: center;
    }

    .webtip_orange {
      font-size: 14px;
      color: #FF9900;
      margin: 0px auto 40px;
      text-align: center;
    }

    .list {
      height: 400px;

      .item {
        background: #FFFFFF;
        border: 1px solid #E9EEF4;
        border-radius: 6px;
        padding: 15px 20px;
        width: 330px;
        cursor: pointer;
        position: relative;
        margin: 0 auto 20px;
        box-sizing: border-box;

        >div:nth-child(1) {
          font-size: 16px;
          color: #333333;
          margin-bottom: 10px;
        }

        >div:nth-child(2) {
          font-size: 14px;
          color: #666;
          margin-bottom: 2px;
        }

        >div:nth-child(3) {
          font-size: 14px;
          color: #666;
        }

        img {
          position: absolute;
          bottom: -1px;
          right: -1px;
        }
      }

      .item.active {
        border: 1px solid #00C8B4;
      }
    }

    .pj {
      text-align: center;

      .subtitle {
        font-size: 14px;
        color: #333333;
        margin-bottom: 16px;
      }

      .star {
        padding-bottom: 20px;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 26px;

        img {
          margin-right: 10px;
          cursor: pointer;
        }

        img:last-child {
          margin-right: 0;
        }
      }

      .tag {
        padding-bottom: 20px;
        cursor: pointer;
        border-bottom: 1px solid #F2F2F2;
        margin-bottom: 26px;

        >div {
          display: inline-block;
          padding: 2px 8px;
          background: #DEDEDE;
          border-radius: 12px;
          font-size: 14px;
          color: #666666;
          margin-right: 10px;
          margin-bottom: 10px;
        }

        .active {
          color: #fff;
          background: #00C8B4;
        }
      }
    }

    .success {
      text-align: center;
      font-size: 18px;
      color: #666666;

      img {
        margin-bottom: 10px;
      }
    }
  }

  .case .info .item>div:nth-child(1) {
    width: auto;
  }

  .case .info .item>div:nth-child(2) {
    text-align: right;
  }

  .wenshu .btn2 {
    text-align: center;
    margin-top: 27px;

    >div {
      display: inline-block;
      width: 120px;
      height: 40px;
      background: #E9EEF4;
      border-radius: 20px;
      text-align: center;
      line-height: 40px;
      font-size: 18px;
      color: #666666;
      cursor: pointer;
    }

    >div:nth-child(2) {
      margin-left: 20px;
      color: #fff;
      background: #00C8B4;
    }
  }
   .user .wifi-cont{
        transform: rotate(310deg);
        right: 10px;
    }
  .wifi-cont{
        width: 25px;
    height: 25px;
    position: relative;
    margin: 0 auto;
    transform: rotate(135deg);
    overflow: hidden;
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    }
    .user .wifi-circle{
      border: 2px solid #fff;
    }
    .wifi-circle{
        box-sizing: border-box;
    border: 2px solid #333;
    border-radius: 50%;
    position: absolute;
    }
    .first{
width: 2.5px;
    height: 2.5px;
    left: 22.5px;
    top: 22.5px;
    }
    .second{
        width: 12.5px;
    height: 12.5px;
    left:17.5px;
    top: 17.5px;
    }
    .third{
        width:20px;
    height: 20px;
    left: 12.5px;
    top: 12.5px;
    }
    .second_animation {
    animation: fade 1s linear .2s infinite;
}
.third_animation {
    animation: fade 1s linear .4s infinite;
}
@keyframes fade {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

</style>
<style lang="scss">
  .chatroom {
    .foot .el-textarea__inner {
      height: 80px;
      background: #E9EEF4;
      border-radius: 6px 6px 10px 6px;
      border: 0;
      margin-bottom: 12px;
      padding: 10px 20px;
      font-size: 18px;
      color: #333333;
    }

    .pj .el-textarea__inner {
      height: 117px;
      background: #EFEFEF;
      border-radius: 10px;
      border: 0;
      padding: 10px 14px;
      font-size: 14px;
      color: #666;
    }

    .el-dialog {
      border-radius: 10px;
    }

    .el-dialog__header {
      padding: 0;
    }

    .wenshu {

      .el-dialog__body {
        padding: 35px 30px;
      }

      .item .el-input__inner {
        height: 26px;
        line-height: 26px;
        background: #E9EEF4;
        border-radius: 4px;
        border: 0;
        padding: 0 7px;
        font-size: 14px;
        width: 180px;
      }

      .el-select .el-input__inner {
        width: 260px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #AAAAAA;
        border-radius: 4px;
      }

      .el-select {
        left: 35px;
      }

    }

    .pj {
      .el-dialog__body {
        padding: 35px 0px;
      }
    }

  }

  .el-select-dropdown .el-scrollbar__wrap {
    overflow-x: scroll !important;
  }

</style>
